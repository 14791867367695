.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}

.default-modal {
  @apply absolute rounded-lg overflow-hidden top-1/2 left-1/2 right-auto bottom-auto p-0 border-0 outline-none focus:outline-none;
  transform: translate(-50%, -50%);
}

.select-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white url('../assets/svg/general/greyCaret.svg') 95% center
    no-repeat !important;
}

.range-select {
  @apply appearance-none h-10 px-4 py-2 text-neutral-700 bg-neutral-200 bg-right-95 bg-no-repeat pr-6 rounded-lg focus:outline-none;
}

.body1 {
  @apply px-4 sm:px-8 py-8 w-full flex-grow flex flex-col z-10;
}

.header1 {
  @apply flex flex-col lg:flex-row justify-between items-center;
}

.body2 {
  @apply flex flex-col px-4 sm:px-8 py-8 w-full relative z-10;
}

.input {
  @apply w-full sm:w-5/12 xl:w-4/12 sm:mr-8;
}

.card {
  @apply border border-grey-3 bg-white w-full rounded-xl flex flex-col justify-between;
}

.orange-gradient {
  background: linear-gradient(100.36deg, #fdc500 1.89%, #f16722 101.06%);
}

.blue-gradient {
  background: linear-gradient(99.38deg, #148cff 1.53%, #14ffc7 124.93%);
}

.purple-gradient {
  background: linear-gradient(99.85deg, #00509d 0%, #4f009d 100%);
}

.red-gradient {
  background: linear-gradient(99.85deg, #ef354c 0%, #9f2333 100%);
}

.leaflet-container {
  z-index: 10;
}

.secondary input[type='radio']:checked ~ .input-text {
  @apply border-dark-blue text-white bg-dark-blue;
}

.secondary input {
  @apply absolute px-3 py-4 w-full h-full;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.section {
  @apply bg-white p-6 rounded-md shadow;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

.transition-element-300 {
  @apply transition duration-300 ease-in-out;
}

.transition-element-700 {
  @apply transition duration-700 ease-in-out;
}

.horizontal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.horizontal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
