/** @format */

@font-face {
  font-family: 'AlbertSans-Regular';
  src: url(../assets/fonts/AlbertSans-Regular.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'AlbertSans-Medium';
  src: url(../assets/fonts/AlbertSans-Medium.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'AlbertSans-SemiBold';
  src: url(../assets/fonts/AlbertSans-SemiBold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'AlbertSans-Bold';
  src: url(../assets/fonts/AlbertSans-Bold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'PassionOne-Black';
  src: url(../assets/fonts/PassionOne-Black.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'PassionOne-Bold';
  src: url(../assets/fonts/PassionOne-Bold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'PassionOne-Regular';
  src: url(../assets/fonts/PassionOne-Regular.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Sora-Light';
  src: url(../assets/fonts/Sora-Light.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Sora-Regular';
  src: url(../assets/fonts/Sora-Regular.ttf) format('truetype');
  font-display: fallback;
}

* {
  font-family: AlbertSans-Regular, sans-serif !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-synthesis: none !important;
}

.medium {
  font-family: AlbertSans-Medium, sans-serif !important;
}

.semibold {
  font-family: AlbertSans-SemiBold, sans-serif !important;
}

.bold {
  font-family: AlbertSans-Bold, sans-serif !important;
}

.passion-bold {
  font-family: PassionOne-Bold, sans-serif !important;
}

.passion-black {
  font-family: PassionOne-Black, sans-serif !important;
}

.passion-regular {
  font-family: PassionOne-Regular, sans-serif !important;
}

.sora {
  font-family: Sora-Regular, sans-serif !important;
}

.sora-light {
  font-family: Sora-Regular, sans-serif !important;
}
