@tailwind base;
@tailwind components;
@tailwind utilities;

@import './theme/fonts.scss';
@import './theme/global.scss';

body {
  margin: 0;
  font-family: AlbertSans-Regular, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.timeline {
  position: relative;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  background-color: red;
  transform: translateX(-50%);
  z-index: -1;
}

.timeline .circle {
  width: 20px;
  height: 20px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  transform: translateX(-50%);
}
